/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./student-profile.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { InputRadio } from "../../../../components/common/input-radio/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import request from "../../../../utils/authUtil";
import requestv3 from "../../../../utils/authUtilv3";
import { Loader } from "../../../../components/common/loader";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const firstApiCall = async (stdAppId: any, check: any) => {
  const res = await request({
    url: `student-application/student-application-consent/?student_app_uuid=${stdAppId}`,
    method: "put",
    data: {
      terms_consent: true,
      code_of_behavior_consent: check,
      anti_bullying_parent_consent: false,
      anti_bullying_student_consent: false,
      picture_permission_consent: false,
      contact_previous_school_consent: true,
      assessment_testing_consent: false,
      acceptable_use_policy: check,
      document_upload_page_consent: true,
    },
  });
  return res;
};

const lateApplicationCheck = async (stdAppId: any) => {
  const res = await requestv3({
    url: `school-settings/send-late-application-email-school-admin/?stu_app_uuid=${stdAppId}`,
    method: "put",
  });
  return res;
};

const StudentProfileSelectionCriteria = (props: Props) => {
  const styles = useStyle();
  const [check, setCheck] = useState<boolean>(false);
  const [error, setError] = useState({
    check: false,
  });
  const school_uuid = localStorage.getItem("school-id");
  const handleChangeRadio = () => {
    setCheck(!check);
    check ? setError({ check: true }) : setError({ check: false });
  };
  const [cobLink, setCobLink] = useState<any>();
  const [aupLink, setAupLink] = useState<any>();

  const goToUrl = (type: string) => {
    type === "cob" ? window.open(cobLink) : window.open(aupLink);
  };

  const schoolID = localStorage.getItem("school-id");
  const [message, setMessage] = useState<string>("");
  // const [aup, setAup] = useState<string>("");
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  // const [showAup, setShowAup] = useState<boolean>(false);
  // const getCustomSettings = () => {
  //   request({
  //     url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
  //     method: "get",
  //   }).then((res) => {
  //     if (res.data.results.allow_acceptable_use_policy) {
  //       setShowAup(true);
  //     }
  //   });
  // };
  useEffect(() => {
    setLoader(true);
    // getCustomSettings();
    request({
      url: `student-application/student-school-application-consent/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setMessage(res.data.results.code_of_behavior_consent);
      // setAup(res.data.results.acceptable_use_policy);
      getUrlLinks();
      setLoader(false);
    });
  }, []);

  const getUrlLinks = async (): Promise<any> => {
    if (school_uuid) {
      await request({
        url: `student-application/student-related-urls-links/?school_uuid=${school_uuid}`,
        method: "get",
      }).then((res) => {
        setCobLink(
          res.data.results.code_Of_behaviour_url.includes("https://")
            ? res.data.results.code_Of_behaviour_url
            : `https://${res.data.results.code_Of_behaviour_url}`
        );
        setAupLink(
          res.data.results.acceptable_use_policy.includes("https://")
            ? res.data.results.acceptable_use_policy
            : `https://${res.data.results.acceptable_use_policy}`
        );
      });
    }
  };

  const validate = () => {
    return {
      check: check === false,
    };
  };

  const onSubmitHandler = async () => {
    const err = validate();
    setError(err);
    if (err.check === false) {
      new Promise((res, rej) => {
        firstApiCall(stdAppId, check)
          .then((result) => {
            if (result?.data?.status_code === 1) {
              lateApplicationCheck(stdAppId)
                .then((response) => {
                  if (response.data.status_code === 1) {
                    res(true);
                  }
                })
                .catch((error) => rej(error));
            }
          })
          .catch((error) => rej(error));
      }).then(async (res: any) => {
        nextPage();
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Loader open={loader} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={6} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome title="Student/Child Profile" />
            </div>
            <Grid container direction="column">
              <p>
                <b>Selection Criteria</b>
              </p>
              <p className={styles.textTwo}>{message}</p>
              {/* {showAup && <p className={styles.textTwo}>{aup}</p>} */}
              <Box>
                <InputRadio
                  radioHolderOne="I confirm"
                  onChange={handleChangeRadio}
                  check={check}
                  error={error.check}
                  errorMessage={"Confirmation is required"}
                />
              </Box>
              <p className={styles.textOne}>
                <div
                  onClick={() => goToUrl("cob")}
                  style={{ color: "black", cursor: "pointer" }}
                >
                  <Box display="flex" alignItems="center">
                    <u>Our Code of Behaviour</u>
                    <Icon className={styles.icon}>north_east</Icon>
                  </Box>
                </div>
              </p>
              {/* {showAup && (
                <p className={styles.textOne}>
                  <div
                    onClick={() => goToUrl("")}
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    <Box display="flex" alignItems="center">
                      <u>Our Acceptable Use Policy</u>
                      <Icon className={styles.icon}>north_east</Icon>
                    </Box>
                  </div>
                </p>
              )} */}
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Button label="Submit" onClick={onSubmitHandler} />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};
export default StudentProfileSelectionCriteria;
